@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
	color: #fff;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
